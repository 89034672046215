<template>
    <v-container fluid fill-height class="pa-0">
        <v-img src="@/assets/img/bglogin2.jpg" width="100vh" dark height="100vh">
            <v-row class="fill-height" align="center">
                <v-col class="text-center" cols="12">
                    <v-card
                        width="400px"
                        class="mx-auto"
                        flat
                        color="transparent"
                        v-scrollanimation
                    >
                        <v-card-title>
                            <v-row justify="center">
                                <v-col cols="12" align="center">
                                    <v-img
                                        alt="TK Logo"
                                        class="shrink mr-2"
                                        contain
                                        src="@/assets/img/TukangKetik.png"
                                        transition="scale-transition"
                                        width="100"
                                    />
                                </v-col>
                                <v-col cols="12" align="center">
                                    <label class="text-h3 text-md-h3 font-weight-black"
                                        >Tukang<font color="black">Ketik</font></label
                                    >
                                </v-col>
                            </v-row>
                        </v-card-title>

                        <v-form ref="form" v-model="valid" :lazy-validation="true">
                            <v-card-text>
                                <v-text-field
                                    prepend-inner-icon="mdi-account"
                                    type="email"
                                    name="Email"
                                    label="Email"
                                    @change="(v) => (email = v + '@tukangketik.net')"
                                    outlined
                                    rounded
                                    :background-color="`black`"
                                    :rules="emailRules"
                                    suffix="@ tukangketik.net"
                                ></v-text-field>
                                <v-text-field
                                    prepend-inner-icon="mdi-lock"
                                    name="Password"
                                    label="Password"
                                    type="password"
                                    v-model="pass"
                                    outlined
                                    rounded
                                    :background-color="`black`"
                                ></v-text-field>
                                <v-btn
                                    rounded
                                    large
                                    block
                                    class="text-weight-bold primary"
                                    @click="login"
                                    >Login</v-btn
                                >
                            </v-card-text>
                        </v-form>
                    </v-card>
                </v-col>
            </v-row>
        </v-img>
    </v-container>
</template>

<script>
import axios from "axios";

export default {
    components: {},
    data: () => ({
        email: "",
        pass: "",
        valid: false,
        emailRules: []
    }),
    methods: {
        async login() {
            if (this.valid) {
                let resp = await axios.post(
                    "/api/login",
                    { email: this.email, pass: this.pass },
                    {
                        headers: {
                            "Content-Type": "application/json"
                        },
                        withCredentials: true
                    }
                );
                if (resp.data.success) {
                    this.$toast.success(resp.data.message, {
                        onClose: () => {
                            this.$router.go();
                        }
                    });
                } else {
                    this.$toast.error(resp.data.message);
                }
            }
        }
    },
    watch: {
        email(value) {
            let v = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value);
            if (!value) {
                this.emailRules = [];
            } else if (!v) {
                this.emailRules = ["Email not valid"];
            } else {
                this.emailRules = [];
            }
        }
    }
};
</script>

<style scoped>
.before-enter {
    opacity: 0;
    transform: translateY(-100px);
    transition: all 0.5s ease-out;
}
.enter {
    opacity: 1;
    transform: translateY(0px);
}
</style>
